import React, {useState, useEffect, useCallback} from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"


import {scrollToSection} from '../components/header';
import Globe from "../videos/alpha_vid.mp4"
import AlphaLogo from "../images/alpha_logo.svg"
import AlphaLogoFull from "../images/alpha_logo_full.png"
import Overpass from "../images/truck_on_overpass.jpg"
import TopView from '../images/homebg.jpg'
import Truck from '../images/truck_unloading.jpg'
import Loading from '../images/loading.jpg'

const AlphaGold = `28, 72%, 57%`

const Section = styled("section")`
  padding-top: 60px;
  padding-bottom: 60px;
  h3{
    font-family: "IBM Plex Sans", sans-serif;
    color: hsl(${AlphaGold});
    font-size: 24px;
    line-height: 1.6;
    font-weight: 700;
    //margin-bottom: 48px;
  }
  &.modules{
    background: linear-gradient(300deg, hsl(${AlphaGold}) 0%, hsl(28, 95%, 81%) 100%);
    display: grid;
    grid-template-columns: 1fr 420px 96px 420px 1fr;
    grid-template-rows: 1fr 360px 96px 360px;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-rows: min-content;
      grid-row-gap: 2vh;
      &>*{
        grid-column: 1 / 1 !important;
        grid-row: auto / span 1 !important;
        padding-left: 24px;
        padding-right: 5vw;
        margin-left: 96px;
        margin-right: 24px;
      }
      & > img{
        display: none;
      }
    }
    & img {
      grid-row: 3/4;
      grid-column: 3/4;
      position:relative;
      top: -12px; 
      z-index: 2;
      filter: drop-shadow(0 0 6px hsl(28, 45%, 31%, 0.6));
    }
    & h2 {
      color: #ffffff;
      font-size: 24px;
      line-height: 2;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      grid-row: 1/2;
      grid-column: 1 / -1;
      padding-bottom: 60px;
      margin-bottom: 0;
    }
  }
  *{
    box-sizing: border-box;
    font-family: 'IBM Plex Sans';
  }
`

const Landing = styled(Section)`
  width: 100vw;
  height: 100vh;
  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  & img#logofull {
    height: auto;
    width: ${props => props.fixLogo ? '180px' : '48vmax'};
    position: fixed;
    left: ${props => props.fixLogo ? '120px' :'84px'};
    top: ${props => props.fixLogo ? '12px' :'84px'};
    z-index: 8;
    transition: all 0.3s ease-out;
    
  }
  
  & svg {
    position: absolute;
    top: 0;
    z-index: -1;
  }
  & div {
    position: absolute;
    top: 50%;
    left: 50%;
    //transform: translateY(-50%);
    & h1{
      font-weight: 700;
      color: hsl(${AlphaGold});
      text-transform: uppercase;
      font-size: 3.5vw;
    }
    & p{
      width: 40vw;
      height: 200px;
      color: #ffffff;
      font-size: 18px;
      line-height: 1.6;
      font-weight: 300;
    }
  }
  @media screen and (max-width: 1024px) {
    & img#logofull{
      width: 180px;
      left: 120px;
      top: 12px;
    }
    & div {
      left: 25%;
      top: 36%;
      & h1{
        font-size: 32px;
        padding-right: 32px;
      }
      & p{
        width: 66vw
      }
    }
  }
`

const CenteredText = styled('div')`
  font-family: "IBM Plex Sans", sans-serif;
  color: #444;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 300;
  text-align: center;
  margin: 0 auto;
  padding-left: 72px;
  padding-right: 48px;
  max-width: 72vw;
  & a, & a:visited{
    text-decoration: none;
    color: unset;
  }
  &.bigger{
    font-size: 24px;
  }
  &.leftaligned{
    max-width: 50vw;
    position: relative;
    left: -7.5vw;
    @media screen and (max-width: 1024px) {
      left: inherit;
      max-width: 90vw;
    }
  }
  @media screen and (max-width: 1024px) {
    padding-left: 84px;
    padding-right: 12px;
    text-align: left;
    max-width: 90vw;
  }
`

const Demographics = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 48px;
  @media screen and (max-width: 1024px) {
   flex-direction: column;
  }
  
`

const Audience = styled('div')`
  width: 360px;
  max-width: 30vw;
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
  p{
    font-family: "IBM Plex Sans", sans-serif;
    color: #444;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
  }
  @media screen and (max-width: 1024px) {
   max-width: 80vw;
   margin-left: 64px;
   margin-right: 0;
   text-align: left;
   padding-left: 36px;
  }
`

const Module = styled('figure')`
  background-color: #ffffff;
  background-image: none;
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
  padding: 48px;
  position: relative;
  transition: transform 0.3s ease-out;
  &:nth-of-type(1){
    grid-row: 2/3;
    grid-column: 2/3;
    background-image: url(${Truck});
  }
  &:nth-of-type(2){
    grid-row: 2/3;
    grid-column: 4/5;
    background-image: url(${Overpass});
  }
  &:nth-of-type(3){
    grid-row: 4/5;
    grid-column: 2/3;
    background-image: url(${TopView});
  }
  &:nth-of-type(4){
    grid-row: 4/5;
    grid-column: 4/5;
    background-image: url(${Loading});
  }
  & > * {
  position: relative;
  z-index: 2;
  }
  & h4{
    color: hsl(${AlphaGold});
    font-size: 18px;
    line-height: 2;
    font-weight: 600;
    text-align: center;
  }
  & p {
    color: #616161;
    font-size: 14px;
    line-height: 2;
    font-weight: 400;
    text-align: center;
  }
  &::before{
    content: '';
    background-color: #fff;
    opacity: 1;
    z-index: 0;
    position:absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-out;
  }
  &:hover{
    transform: scale(1.1);
    &::before{
      opacity: 0.9;
    }
  }
  @media screen and (max-width: 1024px) {
    &:hover{
      transform: none;
      &::before{
        opacity: 1;
      }
    }
  }
`

const CTA = styled('button')`
  display: block;
  padding: 0 36px;
  overflow: visible;
  background: linear-gradient(0deg, hsl(${AlphaGold}) 0%, hsl(39, 100%, 64%) 100%);
  border-radius: 6px;
  appearance: none;
  border: 2px solid hsl(${AlphaGold});
  box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.12), 0 0 0 2px rgba(0, 0, 0, 0.1);
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  font-family: "IBM Plex Sans", SansSerif;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 2;
  text-align: center;
  margin: 0 auto;
  margin-top: 72px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  white-space: nowrap;
  max-width: calc(100vw - 72px);
  &:hover{
    top: -3px;
  }
`

const IndexPage = () => {
  const [scrolledBeyondThreshold, setScrolledBeyondThreshold] = useState(false);

  const handleScroll = useCallback(() => {
    setScrolledBeyondThreshold(window.scrollY > 64)
  }, [setScrolledBeyondThreshold])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [scrolledBeyondThreshold, handleScroll])

  const [ScrollPos, setScrollPos] = useState(0);
  useEffect(()=>{
    window.addEventListener('scroll', () => setScrollPos(window.pageYOffset))
  }, [])

  return (
    <Layout scrolled={scrolledBeyondThreshold}>
      <SEO title="Home" />
      <Landing id={"landing"} fixLogo={scrolledBeyondThreshold}>
        <video src={Globe} autoPlay muted loop controls={false} />
        <svg width="100%" viewBox="0 0 100 100" preserveAspectRatio="xMaxYMax meet">
          <defs>
            <linearGradient id="gold-diagonal" gradientTransform="rotate(249, 0.5, 0.5)">
              <stop offset="0" stopColor="hsl(28, 100%, 35%)" stopOpacity="1" />
              <stop offset="1" stopColor="hsl(28, 70%, 66%)" stopOpacity="1" />
            </linearGradient>
          </defs>
          <path d="M0,0V48L10.75,54L100,0Z" fill="url('#gold-diagonal')" fillOpacity="0.55"/>
          <path transform={"translate(0, -5)"} d="M0,0V48L10.75,54L100,0Z" fill="url('#gold-diagonal')" fillOpacity="0.55"/>
          <path transform={"translate(0, -10)"} d="M0,0V48L10.75,54L100,0Z" fill="url('#gold-diagonal')" fillOpacity="0.55"/>
        </svg>
        <img role="link" id={'logofull'} src={AlphaLogoFull} alt={"Alpha Logo"} onClick={()=>{scrollToSection('landing')}} onKeyUp={()=>{scrollToSection('landing')}}/>
        <div>
          <h1>it takes two to cargo</h1>
          <p>
            Alpha connects shippers and logistic service providers in an innovative shared ecosystem. With smart decisions shippers take control and organize transport from contracted rates. Using a free account, service providers can get access to spot rate requests from the numerous connected shippers, all in one location.
          </p>
        </div>
      </Landing>

      {/*--------------------------------------------------------------------------------------------------------------*/}

      <Section>
        <CenteredText className={'bigger'}>
          With <span style={{color: `hsl(${AlphaGold})`}}>alpha’s</span> ecosystem of connected shippers and service providers,<br />it’s easier than ever to find the best fit for your transport operations.
        </CenteredText>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 609 126"} style={{margin: '0 auto', display: 'block', width: '60vw', marginTop: '48px', marginBottom: '48px', objectFit:'contain' }}>
          <path d="M 488.25 15.75 L 567 15.75 L 567 84 L 488.25 84 Z M 567 42 L 588 42 L 603.75 57.75 L 603.75 84 L 567 84 Z" fill="transparent" strokeWidth="10" stroke="rgb(224, 140, 66)" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M 498.75 97.125 C 498.75 89.876 504.626 84 511.875 84 C 519.124 84 525 89.876 525 97.125 C 525 104.374 519.124 110.25 511.875 110.25 C 504.626 110.25 498.75 104.374 498.75 97.125 Z" fill="transparent" strokeWidth="10" stroke="rgb(224, 140, 66)" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M 567 97.125 C 567 89.876 572.876 84 580.125 84 C 587.374 84 593.25 89.876 593.25 97.125 C 593.25 104.374 587.374 110.25 580.125 110.25 C 572.876 110.25 567 104.374 567 97.125 Z" fill="transparent" strokeWidth="10" stroke="rgb(224, 140, 66)" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M 95 79.259 L 95 42.259 C 94.997 38.958 93.234 35.909 90.375 34.258 L 58 15.758 C 55.138 14.106 51.612 14.106 48.75 15.758 L 16.375 34.258 C 13.516 35.909 11.753 38.958 11.75 42.259 L 11.75 79.259 C 11.753 82.561 13.516 85.61 16.375 87.261 L 48.75 105.761 C 51.612 107.413 55.138 107.413 58 105.761 L 90.375 87.261 C 93.234 85.61 94.997 82.561 95 79.259 Z" fill="transparent" stroke-width="9.25" stroke="rgb(224, 140, 66)" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M 13.247 37.38 L 53.624 60.736 L 94 37.38 M 53.624 107.31 L 53.624 60.69" fill="transparent" strokeWidth="9.25" stroke="rgb(224, 140, 66)" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M 315.891 77.614 L 315.891 93.613 L 293.74 106.308 C 292.495 107.021 290.963 107.019 289.72 106.302 L 263.957 91.434 L 270.908 79.443 L 270.865 79.394 L 290.749 90.868 C 291.37 91.227 292.136 91.228 292.758 90.871 Z M 277.881 19.399 L 289.862 12.533 C 291.107 11.82 292.639 11.822 293.881 12.539 L 331.598 34.305 C 332.84 35.022 333.604 36.345 333.602 37.775 L 333.557 67.49 L 333.51 98.844 C 333.509 99.26 333.285 99.644 332.923 99.852 L 321.33 106.496 C 320.967 106.703 320.522 106.702 320.161 106.494 C 319.799 106.285 319.577 105.9 319.577 105.484 L 319.592 95.587 L 319.637 95.559 L 319.637 71.436 L 319.628 71.444 L 319.669 44.605 C 319.67 43.89 319.288 43.228 318.667 42.87 L 292.853 27.973 C 292.749 27.914 292.641 27.863 292.528 27.822 Z M 284.831 31.403 L 284.872 31.393 L 264.985 42.789 C 264.363 43.146 263.979 43.806 263.978 44.521 L 263.933 74.236 C 263.933 74.315 263.938 74.393 263.946 74.47 L 263.946 91.428 L 252.004 84.537 C 250.762 83.819 249.998 82.497 250 81.066 L 250.066 37.649 C 250.068 36.219 250.836 34.899 252.081 34.185 L 277.875 19.403 Z M 305.748 43.428 L 311.699 46.863 C 312.321 47.221 312.703 47.883 312.701 48.598 L 312.669 70.306 C 312.668 71.021 312.284 71.682 311.661 72.038 L 292.77 82.864 C 292.148 83.221 291.382 83.22 290.761 82.861 L 271.903 71.978 C 271.281 71.62 270.899 70.959 270.901 70.244 L 270.933 48.535 C 270.934 47.82 271.319 47.16 271.941 46.803 L 290.832 35.977 C 291.454 35.62 292.22 35.621 292.841 35.98 L 298.793 39.415 L 277.89 51.393 L 277.878 59.4 L 284.833 63.413 L 284.846 55.407 Z" fill="rgb(224, 140, 66)" />

          <circle r={'60'} cx={'292.5'} cy={'50%'} stroke={`hsl(${AlphaGold})`} strokeWidth='4' fill="none" strokeDasharray={'60 90'} strokeDashoffset={ScrollPos*2} strokeLinecap={"round"} transform={`rotate(${ScrollPos*2} 292.5 63)`}/>
          <line x1={100} y1={'50%'} x2={'38%'} y2={'50%'} stroke={`hsl(${AlphaGold})`} strokeWidth='4' fill="none" strokeDasharray={'60 30'} strokeDashoffset={-ScrollPos} strokeLinecap={"round"} />
          <line x1={"58%"} y1={'50%'} x2={'80%'} y2={'50%'} stroke={`hsl(${AlphaGold})`} strokeWidth='4' fill="none" strokeDasharray={'60 30'} strokeDashoffset={ScrollPos} strokeLinecap={"round"} />
        </svg>
        {/*<img src={Graphic1} alt={"Graphic 1"} style={{margin: '0 auto', display: 'block', width: '60vw', maxWidth: '480px', marginTop: '48px', marginBottom: '48px'}}/>*/}
        <Demographics>
          <Audience>
            <h3>Shipper, meet provider...</h3>
            <p>
              Shippers can quickly compare offers from multiple service providers, request quotes, place orders, print labels, and get your transport on the road, all from one environment.
            </p>
          </Audience>
          <Audience>
            <h3>Provider, meet shipper</h3>
            <p>
              Service providers will receive bookings for contracted rates and receive access to spot rate requests from potential new customers. Are you ready to grow your business?
            </p>
          </Audience>
        </Demographics>
        <CenteredText className={'bigger'} id={'shippers'}>
          Whatever your role in transport, <span style={{color: `hsl(${AlphaGold})`}}>alpha</span> is easy to integrate into your business and reduce your workload.
        </CenteredText>
      </Section>

      {/*--------------------------------------------------------------------------------------------------------------*/}

      <Section className={"modules"} >
        <h2>For shippers we offer a modular approach:</h2>
        <Module>
          <figcaption>
            <h4>Contracted rates</h4>
            <p>
              Upload your contracted rates, lead times and surcharges, independent of the format provided by your service provider. For each shipment easily compare the available offers and make smart decisions.
            </p>
          </figcaption>
        </Module>
        <Module>
          <figcaption>
            <h4>Order</h4>
            <p>
              Immediately book transport on the best offer from contracted or spot rates, print labels & store documents. Manage order statuses & analyse spend.
            </p>
          </figcaption>
        </Module>
        <Module>
          <figcaption>
            <h4>Spot rates</h4>
            <p>
              Quickly request ad-hoc rates for shipments. Select to request only to your contracted partners or to all providers in the alpha ecosystem.
            </p>
          </figcaption>
        </Module>
        <Module >
          <figcaption>
            <h4>Simplified</h4>
            <p>
              Web-based with easy implementation and integration with API/EDI. Up and running within days with guaranteed value for money. Experience <span style={{color: `hsl(${AlphaGold})`}}>alpha</span> in a free trial period.
            </p>
          </figcaption>
        </Module>
        <img src={AlphaLogo} alt={"Alpha Logo"} />
      </Section>

      {/*--------------------------------------------------------------------------------------------------------------*/}

      <Section>
        <span style={{position: 'relative', top: '-148px', display: 'block', visibility: 'hidden'}} id={"providers"}>Offset target for Providers</span>
        <CenteredText>
          <h3>Service providers get started <span style={{textDecoration: 'underline'}}>free of charge</span></h3>
          <p>
            <span style={{color: `hsl(${AlphaGold})`}}>Alpha</span> is a TMS that connects shippers and service providers to manage orders booked from contracted rates or spot requests. With a free account, logistic service providers can access and quote on spot rate requests from all shippers with an <span style={{color: `hsl(${AlphaGold})`}}>alpha</span> account. Successfully executed spot shipments may lead to new structural business. <span style={{color: `hsl(${AlphaGold})`}}>Alpha</span> also provides free access to orders booked by shippers from contracted rates.
          </p>

          <h4 style={{marginTop: '72px'}}>EDI/API integration</h4>
          <p>
            <span style={{color: `hsl(${AlphaGold})`}}>Alpha</span> offers the possibility at low costs to connect by EDI/API to your portal to automate order handling and label printing.
          </p>
        </CenteredText>
      </Section>

      <Section style={{borderTop: `2px dashed hsl(${AlphaGold})`}}>
        <span style={{position: 'relative', top: '-148px', display: 'block', visibility: 'hidden'}} id={"about"}>Offset target for About</span>
        <CenteredText className={'leftaligned'}>
          <h3>About us</h3>
          <p>
            In order to optimize your transportation needs, you often rely on multiple logistics service providers. Each has their own services, strengths and specialities but often their own portal as well. This complicates maintaining insight and overview.<br />
            <span style={{color: `hsl(${AlphaGold})`}}>Alpha</span> was developed to answer the need of shippers for an affordable and easy Transport Management System. Often TMS systems cost hundreds of thousands of euro’s of implementation costs, licences as well as additional transactional based payment structure.<br /><br />
            <span style={{color: `hsl(${AlphaGold})`}}>Alpha</span> has a <em>‘do-it-yourself’</em> philosophy, enabling shippers to set up and manage their own portal. With alpha, a professional TMS is within reach for every size and type of company against a low and fixed yearly license fee, without implementation costs. Managing and optimising transport has never been easier.<br/><br />
            <span style={{color: `hsl(${AlphaGold})`}}>Alpha</span> is powered by <a style={{color: 'hsl(200, 79%, 40%)'}} href={'https://transporttender.nl'} title={"Transport Tender"} target={'_blank'} rel='noopener noreferrer'>Transport Tender BV</a>, a company based in the Netherlands and specialized in procurement of logistics services. <span style={{color: `hsl(${AlphaGold})`}}>Alpha</span> is currently used to manage road and parcel shipments from all over Europe. For short sea and world-wide ocean freight our <a href={'https://transporttender.nl/tools.html#poseadon_intro'} title={"Poseadon"} target={'_blank'} rel='noopener noreferrer' style={{color: 'hsl(200, 79%, 40%)'}}>Poseadon</a> platform is available.
          </p>
        <h3>Convinced yet? If you want to be part of our network, <br />or need more information, simply</h3>
        <CTA id="contact" onClick={()=>{window.location.href = `mailto:info@alpha-tms.com`;}}>Get in touch</CTA>
        </CenteredText>
      </Section>
    </Layout>
  )

}


export default IndexPage
